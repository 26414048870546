<template>
  <div class="px-7 pb-4 mt-5 base-form">
    <v-form @submit.prevent="localOnSubmit" ref="baseForm"> 
      <v-row dense class="form-row">
        <v-text-field v-model="currentItem.apelido" :rules="requiredField" clearable outlined dense>
          <template v-slot:label>
              <span>Nome (Apelido) <span style="color: red;">*</span></span>
          </template>
        </v-text-field>
        <v-text-field v-model="currentItem.nome" :rules="requiredField" clearable outlined dense @input="formatPatientName">
          <template v-slot:label>
              <span>Nome completo <span style="color: red;">*</span></span>
          </template>
        </v-text-field>
        <v-text-field v-model="currentItem.username" :rules="requiredField" clearable outlined dense>
          <template v-slot:label>
              <span>Usuário <span style="color: red;">*</span></span>
          </template>
        </v-text-field>
        <v-text-field v-model="currentItem.email" :rules="[...requiredField, emailValidation]" clearable outlined dense >
          <template v-slot:label>
              <span>E-mail <span style="color: red;">*</span></span>
          </template>
        </v-text-field>
      </v-row>
      <v-row dense>
        <v-col>
          <v-btn-toggle v-model="isActive" mandatory class="white--text">
            <v-btn :color="!isActive ? 'error' : ''">
              Inativo
            </v-btn>
            <v-btn :color="isActive ? 'green' : ''">
              Ativo
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row dense class="justify-end">
         <v-col class="text-end">
          <v-tooltip top :disabled="!isFormValid">
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                <ConfirmButton :color="isFormValid ? 'success' : 'grey'" :loading="isSubmitting" :disabled="!isFormValid || isSubmitting" > Salvar </ConfirmButton>
              </span>
            </template>
            <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import ConfirmButton from "../../../ConfirmButton.vue";
import UtilsFunc from "../../../../service/utilsFunc";

export default {
  name: "BaseForm",
  components: {
    ConfirmButton,
  },
  props: {
    currentItem: Object,
    loading: Boolean,
    onSubmit: Function,
  },
  data: () => ({
    requiredField: [UtilsFunc.isEmptyField('Obrigatório')],
    isSubmitting: false,
  }),
  methods: {
     formatPatientName() {
      if (this.currentItem && this.currentItem.nome) {
        const lowercaseWords = ["a","e", "i", "o", "u", "de", "des", "da", "das", "do", "dos"]; // Palavras que devem permanecer em minúsculas
        this.currentItem.nome = this.currentItem.nome
          .toLowerCase()
          .split(" ")
          .map((word, index) => {
            // Mantém a primeira palavra em maiúsculas mesmo que esteja na lista
            if (index === 0 || !lowercaseWords.includes(word)) {
              return word.charAt(0).toUpperCase() + word.slice(1);
            }
            return word; // Retorna as palavras em minúsculas se estiverem na lista
          })
          .join(" ");
      }
    },
    // gerador de senha automatico aleatória 
    generatePassword(length = 8) {
    const charset = {
      lowercase: 'abcdefghijklmnopqrstuvwxyz',
      uppercase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      number: '0123456789',
      symbol: '!@#$%^&*()_-+=<>?'
    };

    const getRandom = (str) => str[Math.floor(Math.random() * str.length)];

    // Garantir pelo menos um caractere de cada conjunto
    let password = getRandom(charset.lowercase) + getRandom(charset.uppercase)
      + getRandom(charset.number) + getRandom(charset.symbol);

    // Preencher o restante da senha até atingir o comprimento desejado
    const allChars = charset.lowercase + charset.uppercase + charset.number + charset.symbol;
    for (let i = password.length; i < length; i++) {
      password += getRandom(allChars);
    }

    // Embaralhar a senha para garantir aleatoriedade
    return password.split('').sort(() => 0.5 - Math.random()).join('');
    },
    localOnSubmit() {
      const isValidForm = this.$refs.baseForm.validate();
      if (!isValidForm){
        this.$toast.error("Por favor, preencha todos os campos obrigatórios.");
        return;
      }
      this.isSubmitting = true
      // Adiciona a senha gerada automaticamente antes de enviar
      this.currentItem.password = this.generatePassword();
      this.onSubmit();
      this.isSubmitting = false;
    },
     emailValidation(email) {
      const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return pattern.test(email) || 'E-mail inválido.';
    },
  },
  computed: {
    isActive: {
      get() {
        return this.currentItem.is_active ? 1 : 0;
      },
      set(value) {
        return (this.currentItem.is_active = Boolean(value));
      },
    },
    isFormValid() {
      // Verifica se todos os campos obrigatórios estão preenchidos para poder habilitar o botão de Salvar
      return this.currentItem.apelido && 
        this.currentItem.nome && 
        this.currentItem.username && 
        this.currentItem.email;
    },
  },
};
</script>

<style scoped>
.form-row {
  display: flex;
  gap: 1rem;
}
</style>
