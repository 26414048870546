<template>
  <v-row>
    <v-col>
      <v-data-table :loading="isLoading('get:users')" loading-text="Carregando Informações...." :items="filteredData" :search="search" :headers="headers"
        :single-expand="singleExpand" :expanded.sync="expanded" show-expand :sort-by.sync="defaultSort.name"
        :sort-desc.sync="defaultSort.isDesc" class="fixed-header-table" fixed-header  height="700px" :items-per-page="-1"  no-data-text="Nenhum item encontrado para os dados pesquisados ou realize a pesquisa..."> 
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="py-4 grey lighten-2 text-start">
            <ExpandedMultiTeamItem :currentItem="item" />
          </td>
        </template>
        <template v-slot:[`item.grupos`]="{ item }">
          <span v-for="group in item.groups" :key="group.id">
            {{ group.name }}
          </span>
        </template>
        <template v-slot:[`item.especialidade.nome`]="{ item }">
          <span>{{ item.especialidade ? item.especialidade.nome : ''}}</span>
        </template>
        <template v-slot:top>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-row>
                <!-- <v-col cols="5">
                  <v-text-field dense outlined v-model="search" label="Pesquisar" placeholder="Pesquise por Usuário, Nome(Apelido), Nome Completo, etc..." class="mx-0"
                    append-icon="mdi-magnify" clearable/>
                </v-col> -->
                <v-col class="text-end">
                  <!-- <v-badge left bordered overlap :content="activeFilters.length + 1">
                    <v-btn class="mr-2 elevation-0" text v-on:click="showFilters = !showFilters">
                      <v-icon v-if="!showFilters" left> mdi-filter </v-icon> <v-icon v-else left>  mdi-filter-off </v-icon> Filtros </v-btn>
                  </v-badge> -->
                  <v-btn class="mr-4 elevation-0" text @click="collapseAll"> Recolher Linhas </v-btn>
                  <!-- <v-btn class="mr-4 elevation-0" text @click="atualizar"><v-icon left>mdi-reload</v-icon>atualizar</v-btn> -->
                  <v-btn color="success" class="elevation-0" @click="dialog.create = true"> <v-icon left>  mdi-plus </v-icon> Novo </v-btn>
                   <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="elevation-0 ml-5" @click="exportToExcel" v-bind="attrs" v-on="on" style="background-color: transparent;">
                        <v-icon color="green" size="28">mdi-file-excel</v-icon>
                      </v-btn>
                    </template>
                    <span>Exportar para Excel</span>
                  </v-tooltip>
                </v-col>
                <!-- Relatórios, OBS: exluímos o id 171 pois é funcionária e nao prestadora de serviço. -->
                <div class="align-self-top py-4" left bottom v-if="(selectedBudgets.length > 0 && shouldShowImpressionSLHC || shouldShowImpressionLMO) && selectedBudgets[0].id !== 171 "> 
                  <v-menu eager offset-x left offset-y bottom v-model="menu">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class=" align-center mx-8 " color="green" size="30" v-bind="attrs" v-on="on">
                        mdi-printer
                      </v-icon>
                    </template>
                    <v-container class="container">
                      <template >
                        <div v-if="shouldShowImpressionLMO"> 
                          <DeclaracaoAtividadeLMO :selectedBudgets="selectedBudgets" :username="lista.apelido"> </DeclaracaoAtividadeLMO>
                        </div>
                        <div v-else>
                          <DeclaracaoAtividadeSLHC :selectedBudgets="selectedBudgets" :username="lista.apelido"></DeclaracaoAtividadeSLHC>
                        </div>
                      </template>
                    </v-container>
                  </v-menu>
                </div>
              </v-row>
              <v-row dense >
                    <v-col cols="1">
                      <v-select hide-details :items="statusOptions" label="Status" 
                        item-text="label" item-value="value" v-model="isActive" outlined dense />
                    </v-col>
                    <v-col cols="2">
                      <v-select  hide-details :items="especialidadeOptions" label="Especialidade" 
                        item-text="nome" item-value="id"  v-model="filters.especialidade" outlined dense clearable />
                    </v-col>
                    <v-col  cols="2">
                      <v-text-field clearable  label="Usuário" v-model="filters.username" outlined dense />
                    </v-col>
                    <v-col  cols="2">
                      <v-text-field clearable  label="Apelido" v-model="filters.apelido" outlined dense />
                    </v-col>
                    <v-col  cols="2">
                      <v-text-field clearable  label="CPF" v-model="filters.cpf" outlined dense />
                    </v-col>
                    <v-col  cols="1">
                      <v-text-field clearable label="ID do Usuário"  type="Number" v-model="filters.id" outlined dense />
                    </v-col>
                    <v-col >
                        <v-btn color="error" text elevation="0" @click="clearFilters">
                          Limpar
                        </v-btn>
                        <v-btn color="success" class="ml-2" elevation="0" @click="applyFilters">
                          Pesquisar
                        </v-btn>
                      </v-col>
                  </v-row>
              <!-- <v-expand-transition>
                <v-card outlined v-show="showFilters" dense>
                  <h4 class="ml-4 mt-4">Filtrar por</h4>
                  <v-row dense class="py-2 px-4">
                    <v-col cols="2">
                      <v-select hide-details :items="statusOptions" label="Status" 
                        item-text="label" item-value="value" v-model="isActive" outlined dense />
                    </v-col>
                    <v-col cols="2">
                      <v-select  hide-details :items="especialidadeOptions" label="Especialidade" 
                        item-text="nome" item-value="id"  v-model="filters.especialidade" outlined dense clearable />
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-row dense>
                      <v-col class="text-end">
                        <v-btn color="error" text elevation="0" @click="clearFilters">
                          Limpar filtros
                        </v-btn>
                        <v-btn color="success" class="ml-2" elevation="0" @click="applyFilters">
                          Aplicar filtros
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-expand-transition> -->
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.papeis`]="{ item }">
          <span v-for="i in item.papel" :key="`papel-${i.id}`">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs" v-on="on" outlined :color="i.cor" v-if="i.ativo" class="ma-2">
                  {{ i.titulo }}
                  <v-icon>{{ i.icon }}</v-icon>
                </v-chip>
              </template>
              <span>{{ i.titulo }}</span>
            </v-tooltip>
          </span>
        </template>
        <template v-slot:[`item.config`]="{ item }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                        <v-icon color="green">mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>Clique para editar</span>
            </v-tooltip>
        </template>
        <!-- MOstra um aum simbolo na tela para identificar se o usuário tem ou não tem carimbo cadastrado. -->
        <template v-slot:[`item.carimbo`]="{ item }">
          <v-icon v-if="item.carimbo" color="green">mdi-check-circle</v-icon>
        </template>
        <!-- colocar o formato de data na parte de baixo -->
        <template v-slot:[`item.nascimento`]="{ item }">
         {{ item.nascimento ? formatDate(item.nascimento) : '' }}
        </template>
        <template v-slot:[`item.cpf`]="{ item }">
            {{ formatCPF(item.cpf) }}
        </template>
        <template v-slot:[`item.tel`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a :href="`https://wa.me/${item.tel}`" target="_blank" class="whatsapp-link" v-bind="attrs" v-on="on">
                <v-icon v-if="item.tel" color="green">mdi-whatsapp</v-icon>
                <span>{{ formatPhone(item.tel) }}</span>
              </a>
            </template>
            <span>Chamar no WhatsApp</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a :href="`mailto:${item.email}`" class="email-link" v-bind="attrs" v-on="on">
                <v-icon color="blue">mdi-email</v-icon>
                <span>{{ item.email }}</span>
              </a>
            </template>
            <span>Enviar Email</span>
          </v-tooltip>
        </template>
         <template v-slot:[`item.select_budget`]="{ item }">
            <div class="d-flex align-center justify-center">
              <v-checkbox :input-value="selectedBudgets.includes(item)" @change="handleCheckboxChange(item)"></v-checkbox>
            </div>
          </template>
      </v-data-table>
    </v-col>
    <v-dialog v-if="dialog.create" v-model="dialog.create" max-width="800">
      <v-card>
        <v-card-title class="sticky-title title-border">
          Cadastro de novo membro da equipe:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.create = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <BaseForm :currentItem="newItem" :onSubmit="postUsers" :loading="isLoading('post:users')" />
      </v-card>
    </v-dialog>
    <FullScreenDialog v-model="dialog.update" scrollable>
      <v-card>
        <v-card-title class="sticky-title title-border">
          Editar usuário: {{ selectedRow.apelido ? selectedRow.apelido : '' }} - ID: {{ selectedRow.id ? selectedRow.id : ''}}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.update = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-tabs v-model="currentTab">
            <!-- <v-tab>Dados de Acesso</v-tab> -->
            <v-tab v-show="usuarios_dados_pessoais">Dados Pessoais</v-tab>
            <v-tab v-show="usuarios_enderecos">Endereços</v-tab>
            <v-tab v-show="usuarios_dados_bancarios">Dados Bancários</v-tab>
            <v-tab v-show="usuarios_documentos">Documentos</v-tab>
            <!-- <v-tab v-if="isSuperUser">Permissões</v-tab> -->
          </v-tabs>

          <v-tabs-items v-model="currentTab" class="pt-8">
            <!-- <v-tab-item>
              <BaseForm passwordOptional :currentItem="selectedRow" :onSubmit="putUsers"
                :loading="isLoading('put:users')" />
            </v-tab-item> -->
            <v-tab-item v-show="usuarios_dados_pessoais">
              <DadosPessoais :currentItem="selectedRow" :onUpdate="getUsers" />
            </v-tab-item>
            <v-tab-item v-show="usuarios_enderecos">
              <Enderecos :currentItem="selectedRow" />
            </v-tab-item>
            <v-tab-item v-show="usuarios_dados_bancarios">
              <DadosBancarios :currentItem="selectedRow" />
            </v-tab-item>
            <v-tab-item v-show="usuarios_documentos">
              <Documentos  :usuario-id="selectedRow.id"/>
            </v-tab-item>
            <!-- <v-tab-item v-if="isSuperUser">
              <Permissoes :currentItem="selectedRow" />
            </v-tab-item> -->
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </FullScreenDialog>
  </v-row>
</template>
<script>
import api from "@/http";
import UtilsFunc from "../../service/utilsFunc";
import BaseForm from "./componentes/equipe_multidisciplinar/BaseForm.vue";
import DadosPessoais from "./componentes/equipe_multidisciplinar/DadosPessoais.vue";
import DadosBancarios from "./componentes/equipe_multidisciplinar/DadosBancarios.vue";
import Documentos from "./componentes/equipe_multidisciplinar/Documentos.vue";
import FullScreenDialog from "../FullscreenDialog.vue";
import { mapGetters } from 'vuex';
import Enderecos from "./componentes/equipe_multidisciplinar/Enderecos.vue";
// import Permissoes from "./componentes/equipe_multidisciplinar/Permissoes.vue";
import ExpandedMultiTeamItem from "./componentes/equipe_multidisciplinar/ExpandedItem.vue"
import './../../assets/css/styles.css';
import DeclaracaoAtividadeLMO from '../relatorios/documentosEquipe/DeclaracaoAtividade.vue';
import DeclaracaoAtividadeSLHC from '../relatorios/documentosEquipe/DeclaracaoAtividadeSLHC.vue';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const { withCRUDUtils } = UtilsFunc;
export default {
  name: "Equipe",
  components: {
    BaseForm,
    DadosPessoais,
    FullScreenDialog,
    Enderecos,
    DadosBancarios,
    // Permissoes,
    ExpandedMultiTeamItem,
    Documentos,
    DeclaracaoAtividadeLMO,
    DeclaracaoAtividadeSLHC
  },
  data: () =>
    withCRUDUtils({
      search: "",
      currentTab: null,
      loading: false,
      expanded: [],
      especialidadeOptions: [],
      statusOptions: [
        { label: "Ativo", value: true },
        { label: "Inativo", value: false },
      ],
      singleExpand: false,
      data_inicio_: "",
      data_fim_: "",
      items: [],
      showFilters: false,
      isActive: true,
      filters: { status: true, },
      activeFilters: [],
      params: '',
      defaultSort: {
        isDesc: false,
        name: 'apelido'
      },
      newItem: {
        is_active: true,
      },
      headers: [
        { text: "Editar usuário", align: "center", sortable: false, value: "config" },
        { text: "Gerar Documento", align: "center", sortable: false, value: "select_budget" },
        { text: "Id", align: "start", sortable: true, value: "id" },
        { text: "Usuário", align: "start", sortable: true, value: "username" },
        { text: "Nome (Apelido)", value: "apelido", width: 170 },
        { text: "Nome completo", align: "start", sortable: true, value: "nome" },
        { text: "CPF", align: "start", sortable: true, value: "cpf", width: 140 },
        { text: "Função", align: "start", sortable: true, value: "grupos" },
        { text: "Especialidade", align: "start", sortable: true, value: "especialidade.nome" },
        { text: "Telefone", align: "start", sortable: true, value: "tel",width: 170  },
        { text: "E-mail", align: "start", sortable: true, value: "email" },
        { text: "Carimbo", align: "start", sortable: true, value: "carimbo" },
      ],
      usuarios_dados_pessoais:false,
      usuarios_enderecos:false,
      usuarios_dados_bancarios:false,
      usuarios_documentos:false,
      selectedBudgets: [],
      menu: false,
    }),
  methods: {
    async getUsers() {
      const LOADING_NAME = "get:users";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get(`/contas/usuarios/?ativo=${Number(this.isActive)}${this.params}`);
        this.tableData = data;
        this.params = ''
        // this.applyFilters()
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe/ Algo aconteceu de errado. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async postUsers() {
      const LOADING_NAME = "post:users";
      this.setLoading(LOADING_NAME);
      try {
        this.newItem.first_name = this.newItem.nome;
        await api.post("/contas/novo/", this.newItem);
        await this.getUsers();
        this.newItem = { is_active: true };
        this.dialog.create = false;
        this.$toast.success("Usuário Inserido com Sucesso!");
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe. Algo aconteceu de errado. ${error.response.data.username}`)
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async putUsers() {
      const LOADING_NAME = "put:users";
      this.setLoading(LOADING_NAME);
      try {
        const fields = {
          ...this.selectedRow,
          groups: this.selectedRow.groups.map((group) => group.pk),
          carimbo: undefined,
          avatar: undefined
        };

        const { password, username } = fields
        await api.put(`/contas/usuario/${this.selectedRow.id}/`, fields);

        if (password) {
          await api.put(
            `contas/change_password_back_office/${this.selectedRow.id}/`,
            { password, password2: password, username }
          );
        }
        await this.getUsers();
        // alert
        this.$toast.success("Usuário Atualizado com Sucesso!");
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe/ Algo aconteceu de errado. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async applyFilters() {
      this.search = ''
      this.selectedBudgets = ''
      // Remove a formatação do CPF antes de enviar
      const cleanedCPF = this.filters.cpf ? this.filters.cpf.replace(/\D/g, '') : null;
  
      Object.keys(
      this.filters).forEach(k => 
        {
          if(this.filters[k] !== null)
            this.params += `&${k}=${k === 'cpf' ? cleanedCPF : this.filters[k]}`;
        })

      this.getUsers()
      
    },
    clearFilters() {
      this.activeFilters = []
      this.search = ''
      this.params = ''
      this.selectedBudgets = ''
      this.isActive = true
      this.filters = {
        status: true,  // Volte ao estado padrão ou valor inicial dos filtros
        especialidade: null,
        username: null,
        apelido: null,
        cpf: null,
        id: null
      };
      this.tableData = [];
      // Limpar os resultados da pesquisa e recarregar a página com os dados limpos
      // this.getUsers();  // Recarrega os dados sem filtros aplicados
    },
    // Método para recolher todoas as linhas expandidadas
    collapseAll() {
      this.expanded = [];
    },
    atualizar(){
      this.search = ''
      this.selectedBudgets = ''
      this.clearFilters()
      this.collapseAll();
      this.getUsers()
      this.get_listas('contas/especialidades/', 'especialidadeOptions')
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const parts = dateString.split('-');
      return `${parts[2]}/${parts[1]}/${parts[0]}`;
    },
    async get_listas(url, lista){
      try {
        const response = await api.get(url)
        if(response.status === 200){
          // verifica se a resposta não é um array vazio
          if (response.data instanceof Array && response.data.length) {
            // ordena o array com base no campo 'nome'
            response.data.sort((a, b) => {
              if (a.nome < b.nome) {
                return -1;
              }
              if (a.nome > b.nome) {
                return 1;
              }
              return 0;
            });
          }
          this[lista] = response.data
        }
      } catch(error) {
        console.error("Erro ao buscar lista:", error);
      }
    },
    formatPhone(phone) {
      if (!phone) return '';
      const cleaned = ('' + phone).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})$/);
      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      }
      return phone;
    },
    formatCPF(cpf) {
      if (!cpf) return '';
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    async validaPermissao(sub_aba){
      try {
        const response = await api.get(`contas/permissoes-subaba/?sub_aba=${sub_aba}`)
        if(response.status === 200){
          this[sub_aba] = response.data.status
        }
      } catch(error) {
        console.error("Erro ao buscar lista:", error);
      }
    },
    handleCheckboxChange(item) {
      if (!Array.isArray(this.selectedBudgets)) {
        this.selectedBudgets = []; // Garante que selectedBudgets é um array
      }

      if (this.selectedBudgets.includes(item)) {
        this.selectedBudgets = []; // Se o item já estiver selecionado, desmarque-o.
      } else {
        this.selectedBudgets = [item]; // Selecione o novo item, desmarcando todos os outros.
      }
    },
    exportToExcel() {
      try {
        const worksheet = XLSX.utils.json_to_sheet(this.filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'EquipeMultiprofissional');

        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'equipemultiprofissional.xlsx');
      } catch (error) {
        console.error('Erro ao exportar para Excel:', error);
      }
    },
  },

  mounted() {
    // this.getUsers()
    this.get_listas('contas/especialidades/', 'especialidadeOptions')
    this.validaPermissao('usuarios_dados_pessoais')
    this.validaPermissao('usuarios_enderecos')
    this.validaPermissao('usuarios_dados_bancarios')
    this.validaPermissao('usuarios_documentos')
  },
  computed: {
    ...mapGetters(['userData']),
    isSuperUser() {
      return this.$store.state.userData.is_superuser
    },
    filteredData() {
      const filterBy = UtilsFunc.createFilter(this.activeFilters)
      return this.tableData.filter(filterBy)
    },
    shouldShowImpressionLMO() {
      return Array.isArray(this.selectedBudgets) && this.selectedBudgets.some(item =>
        [1, 2, 5, 7, 8].includes(item.especialidade.id)
      );
    },
    shouldShowImpressionSLHC() {
      return Array.isArray(this.selectedBudgets) && this.selectedBudgets.some(item =>
        [3].includes(item.especialidade.id)
      );
    },
    lista() {
      return this.userData || {}
    },
  },
  watch: {
  'filters.cpf'(newVal) {
    if (!newVal) return;

    // Remove qualquer caractere não numérico
    const cleaned = newVal.replace(/\D/g, '');

    // Aplica a formatação CPF: XXX.XXX.XXX-XX
    if (cleaned.length <= 11) {
      this.filters.cpf = cleaned.replace(/(\d{3})(\d{3})(\d{3})(\d{0,2})/, (match, p1, p2, p3, p4) => {
        return `${p1}.${p2}.${p3}-${p4}`;
      });
    } else {
      this.filters.cpf = newVal;  // Se o valor for maior que o esperado, mantém sem formatação
    }
  }
}

};
</script>

<style scoped>
.title-border {
  border-bottom: 2px solid #E0E0E0; /* ajuste a cor conforme necessário */
  padding-bottom: 10px;
}

.table-container {
  max-height: 500px; /* Define a altura máxima para o contêiner da tabela */
  overflow-y: auto;  /* Permite a rolagem vertical */
}

.fixed-header-table thead th {
  background-color: white; /* Cor de fundo do cabeçalho para que ele permaneça visível */
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>


